import config from 'app/config/config';
import { dispatch, getState } from 'app/reducers/configureStore';
import { setFeedbackObj, setHideBeatLoader, setShowBeatLoader, setSnackBarAlert } from 'app/reducers/slices/AlertsSlice';
import {
  clearIconPanalSelections,
  setIconList,
  setIconVariantsAndVersionsMergedData,
  setIconVariantsList,
  setSelectedIcon
} from 'app/reducers/slices/IconsSlice';
import { setQueryResponse } from 'app/reducers/slices/QueryResultSlice';
import { setUserData } from 'app/reducers/slices/UserSlice';
import axios from 'app/utils/axios';
import { downloadFiles, errorMessageHandler, handleError, userDetailFormatter } from 'app/utils/helpers';

const executeQueryFormatter = (value, isAdmin = false) => {
  let filteredData = null;

  if (isAdmin) {
    filteredData = value;
  } else {
    const { mark_for_delete, mark_for_delete_comments, ...rest } = value;
    filteredData = rest;
  }

  return {
    ...filteredData,
    thumbnail: value?.file_link
  };
};

const tableStructureFormatter = (values) => {
  return values.map((value) => ({
    columnName: value.column_name,
    dataType: value.data_type,
    characterMaximumLength: value.character_maximum_length
  }));
};

const uploadBatch = async (batch) => {
  try {
    const { data } = await axios.post(
      `/api/post-medias/`,
      { files: batch },
      {
        rawRequest: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );

    if (data?.status) {
      return data?.data?.map((item) => ({
        fileId: item.file_id,
        fileName: item.file_name,
        fileSizeKb: item.file_size_kb,
        fileExtension: item.file_extension,
        filePath: item.file_path,
        fileUrl: item.file_link,
        thumbnail: item.file_link,
        id: item.file_id,
        createdBy: item.created_by,
        updatedBy: item.updated_by,
        createdAt: item.created_at,
        updatedAt: item.updated_at
      }));
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
      throw new Error(data.message);
    }
  } catch (e) {
    handleError(e);
    throw e;
  } finally {
  }
};

export const bulkFileDownload = async (body) => {
  try {
    dispatch(setShowBeatLoader());
    const response = await axios.post(`api/post-medias/download/bulk`, { file_ids: body }, { responseType: 'blob' });

    const contentType = response.headers['content-type'];

    if (contentType.includes('application/json')) {
      let jsonData = await response.data.text();
      jsonData = JSON.parse(jsonData);
      dispatch(setSnackBarAlert({ message: jsonData?.message, severity: 'error' }));
    } else if (response.headers['content-disposition']) {
      downloadFiles(response);

      dispatch(setSnackBarAlert({ message: 'Downloaded successfully', severity: 'success' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const craeteNewThemeIcon = async (payload) => {
  let resObj = null;
  dispatch(setShowBeatLoader());
  try {
    const { data: response } = await axios.post('/api/icon-theme', payload);
    if (response.status) {
      resObj = response;
    } else {
      const { errors } = response;
      resObj = { status: response.status, errors };
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return resObj;
  }
};

export const craeteNewIconInTheme = async (requestBody) => {
  let resObj = null;
  dispatch(setShowBeatLoader());
  try {
    const { data: response } = await axios.post(`/api/icons`, requestBody);
    if (response.status) {
      resObj = response;
    } else {
      const { errors } = response;
      resObj = { status: response.status, errors: errors || [] };
      if (!errors) {
        dispatch(setSnackBarAlert({ message: errorMessageHandler(response)[0], severity: 'error' }));
      }
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return resObj;
  }
};

export const craeteNewVariatInIcon = async (formdata) => {
  let resObj = null;
  try {
    dispatch(setShowBeatLoader());
    const { data: response } = await axios.post('api/icon-variations/', formdata, {
      rawRequest: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    if (response.status) {
      resObj = response;
    } else {
      const { errors } = response;
      resObj = { status: response.status, errors: errors || [] };
      if (!errors) {
        dispatch(setSnackBarAlert({ message: errorMessageHandler(response)[0], severity: 'error' }));
      }
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return resObj;
  }
};

export const craeteNewVersionInIconVariant = async (formdata) => {
  let resObj = null;
  try {
    dispatch(setShowBeatLoader());
    const { data: response } = await axios.post('api/icon-variation-versions/', formdata, {
      rawRequest: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    if (response.status) {
      resObj = response;
    } else {
      const { errors } = response;
      resObj = { status: response.status, errors: errors || [] };
      if (!errors) {
        dispatch(setSnackBarAlert({ message: errorMessageHandler(response)[0], severity: 'error' }));
      }
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return resObj;
  }
};

export const deleteMediabyId = async (id, deletionReason = '') => {
  const isNormalUser = getState().User.isNormalUser;
  let isDeleted = false;
  let deleteURL = '';
  const deletePayload = { file_ids: id };
  if (isNormalUser) {
    deleteURL = '/api/post-medias/delete/bulk';
    deletePayload['delete_comments'] = deletionReason;
  } else {
    deleteURL = '/api/post-medias/admin/delete/bulk';
  }
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.delete(deleteURL, { data: deletePayload });

    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Deleted successfully', severity: 'success' }));
      isDeleted = data?.status;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return isDeleted;
  }
};

export const activateIconVariationVersion = async (id) => {
  let isActivated = false;
  try {
    const selectedIcon = getState().Icons.selectedIcon;
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`/api/icon-variation-versions/activate/${id}`);

    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Activated successfully', severity: 'success' }));
      if (selectedIcon) {
        const payload = {
          page: 1,
          page_size: 1000,
          filters: [{ column_name: 'icon_id', type: 'exact', value: selectedIcon?.icon_id }],
          sorts: [{ column_name: 'id', direction: 'asc' }]
        };
        const iconsVariantsByIconId = await getIconsVariantsByIconId(payload);
        let variantsList = [];
        if (iconsVariantsByIconId?.data?.length) {
          dispatch(setIconVariantsAndVersionsMergedData(iconsVariantsByIconId.data));
          variantsList = iconsVariantsByIconId.data.map((variants) => {
            const { iconVariationVersions, ...variantsData } = variants;
            let activeVersion = null;
            iconVariationVersions.forEach((version) => {
              if (version.is_active) {
                activeVersion = version;
              }
            });
            if (!activeVersion) {
              activeVersion = iconVariationVersions[0];
            }

            return { ...variantsData, file_link: activeVersion.file_link };
          });
        }
        dispatch(setIconVariantsList(variantsList));
      }
      isActivated = data?.status;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return isActivated;
  }
};

export const executeQuery = async (body) => {
  try {
    const isNormalUser = getState().User.isNormalUser;
    const { data } = await axios.post(`/api/query`, { query: body });

    if (data?.status) {
      const response = data.data.map((data) => executeQueryFormatter(data, !isNormalUser));
      dispatch(setQueryResponse(response));
      dispatch(setFeedbackObj(null));
    } else {
      dispatch(setFeedbackObj(data));
    }
  } catch (error) {
    handleError(error);
  }
};

export const fileDownload = async (params) => {
  try {
    dispatch(setShowBeatLoader());

    const response = await axios.get(`api/post-medias/download/${params.file_id}`, { responseType: 'arraybuffer' });

    if (response?.data?.status === false) {
      dispatch(setSnackBarAlert({ message: response.data?.message, severity: 'error' }));
    } else if (response.headers['content-disposition']) {
      downloadFiles(response);
      dispatch(setSnackBarAlert({ message: 'Downloaded successfully', severity: 'success' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getIconsByThemeId = async (requestBody) => {
  dispatch(setShowBeatLoader());
  try {
    const { data: res } = await axios.post(`/api/icons/listing`, requestBody);

    if (res?.status) {
      const { count, total_count, total_page_count, page, page_size, data } = res;

      return {
        count: count,
        totalCount: total_count,
        totalPageCount: total_page_count,
        page: page,
        pageSize: page_size,
        data: data
      };
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getIconsVariantsByIconId = async (requestBody) => {
  dispatch(setShowBeatLoader());
  try {
    const { data: res } = await axios.post(`/api/icon-variations/listing`, requestBody);

    if (res?.status) {
      const { count, total_count, total_page_count, page, page_size, data } = res;

      return {
        count: count,
        totalCount: total_count,
        totalPageCount: total_page_count,
        page: page,
        pageSize: page_size,
        data: data
      };
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getTableDefinition = async () => {
  let tableData = null;
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.post(`/api/query/table_definition`, {
      table_name: 'post_medias'
    });
    if (data?.status) {
      const formattedData = tableStructureFormatter(data.data);
      tableData = formattedData;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    tableData = null;
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return tableData;
  }
};

export const getThemeIconStats = async () => {
  let tableData = [];
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`/api/icon-theme/icons_stats/`);
    if (data?.status) {
      tableData = data?.data;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return tableData;
  }
};

export const getThemeIcon = async (requestBody) => {
  try {
    const { data: res } = await axios.post(`/api/icon-theme/listing`, requestBody);

    if (res?.status) {
      const { count, total_count, total_page_count, page, page_size, data } = res;

      return {
        count: count,
        totalCount: total_count,
        totalPageCount: total_page_count,
        page: page,
        pageSize: page_size,
        data: data
      };
    }
  } catch (error) {
    handleError(error);
  }
};

export const getUserDetails = async () => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`${config.AUTH_BACKEND_URL}/api/user_details`);

    if (data?.status) {
      dispatch(setUserData(userDetailFormatter(data.data)));
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getUserMedia = async (requestBody) => {
  try {
    const { data } = await axios.post(`/api/post-medias/listing`, requestBody);

    if (data?.status) {
      const response = {
        count: data.count,
        totalCount: data.total_count,
        totalPageCount: data.total_page_count,
        page: data.page,
        pageSize: data.page_size,
        data: data.data.map((data) => executeQueryFormatter(data))
      };

      return response;
    }
  } catch (error) {
    handleError(error);
  }
};

export const logout = async () => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`${config.AUTH_BACKEND_URL}/api/logout`);

    if (data?.status) {
      return true;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const singleFileUpload = async (formdata, fileId) => {
  const isNormalUser = getState().User.isNormalUser;
  let updatedFile = null;
  const updatedURL = isNormalUser ? '/api/post-medias' : '/api/post-medias/admin';
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.put(`${updatedURL}/${fileId}`, formdata, {
      rawRequest: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Uploaded successfully', severity: 'success' }));
      updatedFile = data;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    updatedFile = null;
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return updatedFile;
  }
};

export const uploadFiles = async (files) => {
  let uploadBatchFailed = false;
  dispatch(setShowBeatLoader());

  const chunkSize = 10;
  const fileChunks = [];
  for (let i = 0; i < files.length; i += chunkSize) {
    fileChunks.push(files.slice(i, i + chunkSize));
  }

  let uploadedFiles = [];
  for (const chunk of fileChunks) {
    try {
      const result = await uploadBatch(chunk);
      uploadedFiles = [...uploadedFiles, ...result];
    } catch (e) {
      uploadBatchFailed = true;
      break; // Stop processing further batches if an error occurs
    }
  }

  if (uploadBatchFailed) {
    dispatch(setSnackBarAlert({ message: 'Uploading Failed', severity: 'error' }));
  } else {
    dispatch(setSnackBarAlert({ message: 'Uploaded successfully', severity: 'success' }));
  }

  dispatch(setHideBeatLoader());

  return uploadedFiles;
};

export const deleteThemeIconbyId = async (id, deletionReason = '') => {
  let isDeleted = false;

  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.delete(`/api/icon-theme/${id}`);

    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Deleted successfully', severity: 'success' }));
      isDeleted = data?.status;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return isDeleted;
  }
};

export const getIconDetails = async (themeShortCode, iconShortCode) => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`/api/icons/${themeShortCode}/icons/${iconShortCode}`);
    if (data?.status) {
      return data.data;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const deleteIconbyId = async (id, deletionReason = '') => {
  let isDeleted = false;
  try {
    const selectedIcon = getState().Icons.selectedIcon;
    const selectedTheme = getState().Icons.selectedTheme;
    dispatch(setShowBeatLoader());
    const { data } = await axios.delete(`/api/icons/${id}`);
    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Deleted successfully', severity: 'success' }));
      if (selectedIcon?.id === id) {
        dispatch(clearIconPanalSelections());
      }
      if (selectedTheme) {
        const payload = {
          page: 1,
          page_size: 1000,
          filters: [{ column_name: 'theme_id', type: 'exact', value: selectedTheme.theme_id }],
          sorts: [{ column_name: 'id', direction: 'asc' }]
        };
        const iconsByThemeId = await getIconsByThemeId(payload);
        let formatIconList = [];
        if (iconsByThemeId?.data?.length) {
          formatIconList = iconsByThemeId.data;
        }
        dispatch(setIconList(formatIconList));
        dispatch(setSelectedIcon(formatIconList.length ? formatIconList[0] : null));
      }
      isDeleted = data?.status;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return isDeleted;
  }
};

export const deleteVariationsIconbyId = async (id, deletionReason = '') => {
  let isDeleted = false;
  try {
    const selectedIcon = getState().Icons.selectedIcon;
    dispatch(setShowBeatLoader());
    const { data } = await axios.delete(`/api/icon-variations/${id}`);

    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Deleted successfully', severity: 'success' }));
      if (selectedIcon) {
        const payload = {
          page: 1,
          page_size: 1000,
          filters: [{ column_name: 'icon_id', type: 'exact', value: selectedIcon?.icon_id }],
          sorts: [{ column_name: 'id', direction: 'asc' }]
        };
        const iconsVariantsByIconId = await getIconsVariantsByIconId(payload);
        let variantsList = [];
        if (iconsVariantsByIconId?.data?.length) {
          dispatch(setIconVariantsAndVersionsMergedData(iconsVariantsByIconId.data));
          variantsList = iconsVariantsByIconId.data.map((variants) => {
            const { iconVariationVersions, ...variantsData } = variants;
            let activeVersion = null;
            iconVariationVersions.forEach((version) => {
              if (version.is_active) {
                activeVersion = version;
              }
            });
            if (!activeVersion) {
              activeVersion = iconVariationVersions[0];
            }

            return { ...variantsData, file_link: activeVersion.file_link };
          });
        }
        dispatch(setIconVariantsList(variantsList));
      }
      isDeleted = data?.status;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return isDeleted;
  }
};

export const updateThemeIconById = async (payload, themeIconId) => {
  let resObj = null;
  dispatch(setShowBeatLoader());
  try {
    const { data: response } = await axios.put(`/api/icon-theme/${themeIconId}`, payload);
    if (response.status) {
      resObj = response;
    } else {
      const { errors } = response;
      resObj = { status: response.status, errors };
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
    return resObj;
  }
};
