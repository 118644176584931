import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userMediaData: null
};

export const UserMediaSlice = createSlice({
  name: 'UserMediaSlice',
  initialState,
  reducers: {
    setUserMediaData: (state, action) => {
      state.userMediaData = action.payload;
    }
  }
});

export const { setUserMediaData } = UserMediaSlice.actions;

export default UserMediaSlice.reducer;
