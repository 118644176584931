import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  themeIconData: null
};

export const ThemeIconSlice = createSlice({
  name: 'ThemeIconSlice',
  initialState,
  reducers: {
    setThemeIconData: (state, action) => {
      state.themeIconData = action.payload;
    }
  }
});

export const { setThemeIconData } = ThemeIconSlice.actions;

export default ThemeIconSlice.reducer;
