import { lazy, useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import { setTokenInLocalStorage } from './utils/helpers';
import { getUserDetails } from './utils/apiCalls';

const Dashboard = Loadable(lazy(() => import('app/views/Dashboard')));
const MediaListing = Loadable(lazy(() => import('app/views/MediaListing')));
const MediaAdd = Loadable(lazy(() => import('app/views/MediaAdd')));
const UserMedia = Loadable(lazy(() => import('app/views/UserMedia')));
const ThemeIcon = Loadable(lazy(() => import('app/views/ThemeIcon')));
const ThemeIconVariants = Loadable(lazy(() => import('app/views/ThemeIconVariants')));
const Icons = Loadable(lazy(() => import('app/views/Icons')));

const adminRoutes = [
  { path: '/', element: <Dashboard /> },
  { path: 'media', element: <MediaListing /> },
  { path: 'media/add', element: <MediaAdd /> },
  { path: '/user-media', element: <UserMedia /> },
  { path: '/theme-icon', element: <ThemeIcon /> },
  { path: '/theme-icon/variants', element: <ThemeIconVariants /> },
  { path: '/icons', element: <Icons /> }
];

const Router = () => {
  const location = useLocation();

  useEffect(() => {
    const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('access_token');

    if (fullUrl.includes(accessToken)) {
      window.location.href = `${window.location?.origin}`;
    }

    if (accessToken) {
      setTokenInLocalStorage(accessToken);
    }
  }, [location.search, location.pathname]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const routes = useRoutes([
    {
      path: '/',
      element: <MatxLayout />,
      children: adminRoutes
    },

    { path: '*', element: <Navigate to="/" /> }
  ]);

  return routes;
};

export default Router;
