import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryResponse: []
};

const QueryResultSlice = createSlice({
  name: 'QueryResultSlice',
  initialState,
  reducers: {
    setQueryResponse: (state, action) => {
      state.queryResponse = action.payload;
    }
  }
});

export const { setQueryResponse } = QueryResultSlice.actions;
export default QueryResultSlice.reducer;
