import { configureStore } from '@reduxjs/toolkit';
import AlertsSlice from './slices/AlertsSlice';
import UserSlice from './slices/UserSlice';
import LayoutSlice from './slices/LayoutSlice';
import QueryResultSlice from './slices/QueryResultSlice';
import ThemeIconSlice from './slices/ThemeIconSlice';
import UserMediaSlice from './slices/UserMediaSlice';
import IconsSlice from './slices/IconsSlice';

const store = configureStore({
  reducer: {
    Alerts: AlertsSlice,
    User: UserSlice,
    Layout: LayoutSlice,
    QueryResult: QueryResultSlice,
    ThemeIcon: ThemeIconSlice,
    UserMedia: UserMediaSlice,
    Icons: IconsSlice
  }
});

export const { dispatch, getState } = store;

export default store;
