import { ACCESS_TOKEN_KEY } from 'app/config/Constants';
import { dispatch, getState } from 'app/reducers/configureStore';
import { setSnackBarAlert } from 'app/reducers/slices/AlertsSlice';
import { isCancel } from 'axios';
import { get } from 'lodash';

const setTokenInLocalStorage = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }
};

const getTokenFromLocalStorage = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

const downloadFiles = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `${get(response, 'headers.content-disposition', 'files')}`.replace(/"/g, '').split('=')[1];

  link.setAttribute('download', fileName.toString());
  document.body.appendChild(link);
  link.click();
};

const getFileExtension = (fileName) => {
  return fileName?.split('.')?.pop()?.toLowerCase();
};

const userDetailFormatter = (user) => ({
  type: user.type,
  guid: user.guid,
  entity_url_email: user?.entity_url_email,
  username: user?.username
});

const updateQueryFormatter = (value) => {
  const currentDate = new Date();
  const fileLink = `${value.file_link}?random=${currentDate.getTime()}`;
  return {
    ...value,
    file_link: fileLink,
    thumbnail: fileLink
  };
};

const formatThemeIconData = (rawData) => {
  return {
    id: rawData.id,
    name: rawData.name,
    short_code: rawData.short_code,
    theme_media_url: rawData.theme_media_url,
    is_active: rawData.is_active,
    theme_id: rawData.theme_id,
    thumbnail: rawData.theme_media_url,
    description: rawData.description,
    created_at: rawData.created_at,
    updated_at: rawData.updated_at
  };
};

const getColor = (isDragAccept, isDragReject, isFocused) => {
  if (isDragAccept) {
    return '#00e676';
  }
  if (isDragReject) {
    return '#ff1744';
  }
  if (isFocused) {
    return '#2196f3';
  }
  return '#000';
};

const getCommonStyles = (isSelected = false) => {
  const commonStyles = {
    border: '1px solid gray',
    borderRadius: '8px',
    padding: '8px',
    marginTop: '8px'
  };

  if (isSelected) {
    commonStyles['backgroundColor'] = '#e3e3e3';
  }

  return commonStyles;
};

const getIconVersionsByVariantId = (variantId, variantsAndVersionsMergedData = null) => {
  if (!variantId) return [];

  let completeData = [];
  let versionsList = [];
  let activeVersion = null;

  if (variantsAndVersionsMergedData) {
    completeData = variantsAndVersionsMergedData;
  } else {
    completeData = getState().Icons.iconVariantsAndVersionsMergedData;
  }

  completeData.forEach((data) => {
    if (data.variation_id === variantId) {
      const { iconVariationVersions } = data;
      versionsList = iconVariationVersions;
    }
  });

  versionsList.forEach((version) => {
    if (version.is_active) {
      activeVersion = version;
    }
  });

  // if (!activeVersion) {
  //   activeVersion = versionsList[0];
  // }

  return { activeVersion, versionsList };
};

const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

const errorMessageHandler = (responseData) => {
  const errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e?.message ?? e));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const handleError = (error) => {
  if (!isCancel(error)) {
    console.error('error', error);
    dispatch(setSnackBarAlert({ message: 'Something went wrong!', severity: 'error' }));
  }
};

export {
  convertHexToRGB,
  downloadFiles,
  errorMessageHandler,
  formatThemeIconData,
  getColor,
  getCommonStyles,
  getFileExtension,
  getIconVersionsByVariantId,
  getTokenFromLocalStorage,
  handleError,
  setTokenInLocalStorage,
  updateQueryFormatter,
  userDetailFormatter
};
