import { lazy, Suspense } from 'react';
import { MatxLoading } from 'app/components';

const Layout = lazy(() => import('./Layout1/Layout1'));

const MatxSuspense = ({ children }) => {
  return <Suspense fallback={<MatxLoading />}>{children}</Suspense>;
};

const MatxLayout = (props) => {
  return (
    <MatxSuspense>
      <Layout {...props} />
    </MatxSuspense>
  );
};

export default MatxLayout;
