import { createSlice } from '@reduxjs/toolkit';
import { userType } from 'app/config/Constants';

const initialState = {
  userData: null,
  isNormalUser: null
};

export const UserSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      state.isNormalUser = action.payload.type === userType.user;
    }
  }
});

export const { setUserData } = UserSlice.actions;

export default UserSlice.reducer;
