import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  themeList: [],
  selectedTheme: null,
  iconList: [],
  selectedIcon: null,
  iconVariantsAndVersionsMergedData: null,
  iconVariantsList: [],
  selectedVariant: null,
  iconVariantVersionsList: []
};

export const IconsSlice = createSlice({
  name: 'IconsSlice',
  initialState,
  reducers: {
    setThemeList: (state, action) => {
      state.themeList = action.payload;
    },
    setSelectedTheme: (state, action) => {
      state.selectedTheme = action.payload;
      state.iconVariantsList = [];
      state.iconVariantVersionsList = [];
    },
    setIconList: (state, action) => {
      state.iconList = action.payload;
    },
    setSelectedIcon: (state, action) => {
      state.selectedIcon = action.payload;
      state.selectedVariant = null;
      state.iconVariantsList = [];
      state.iconVariantVersionsList = [];
    },
    setIconVariantsAndVersionsMergedData: (state, action) => {
      state.iconVariantsAndVersionsMergedData = action.payload;
    },
    setIconVariantsList: (state, action) => {
      state.iconVariantsList = action.payload;
    },
    setSelectedVariant: (state, action) => {
      state.selectedVariant = action.payload;
    },
    setIconVariantVersionsList: (state, action) => {
      state.iconVariantVersionsList = action.payload;
    },
    clearIconPanalSelections: (state) => {
      state.selectedIcon = null;
      state.selectedVariant = null;
      state.iconVariantsList = [];
      state.iconVariantVersionsList = [];
    }
  }
});

export const {
  setThemeList,
  setSelectedTheme,
  setIconList,
  setSelectedIcon,
  setIconVariantsAndVersionsMergedData,
  setIconVariantsList,
  setSelectedVariant,
  setIconVariantVersionsList,
  clearIconPanalSelections
} = IconsSlice.actions;

export default IconsSlice.reducer;
