import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSidebar: false
};

export const LayoutSlice = createSlice({
  name: 'LayoutSlice',
  initialState,
  reducers: {
    setShowSidebar: (state, action) => {
      state.showSidebar = action.payload;
    }
  }
});

export const { setShowSidebar } = LayoutSlice.actions;

export default LayoutSlice.reducer;
