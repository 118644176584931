import config from './config';

export const sideNavWidth = 260;
export const sidenavCompactWidth = 80;
export const MAX_SIZE = 5 * 1024 * 1024; //max size is 5 mb
export const MaxTextfieldLimit = 16000;

export const ACCESS_TOKEN_KEY = 'access_token';
export const redirectUrl = `${config.AUTH_FRONTEND_URL}?redirect_url=${window.location.href}`;

export const allowedTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'ico', 'heif', 'heic'];
export const thumbnailAllowedExtension = ['png', 'jpg', 'jpeg']; // only these extension thumbnail is being generated others thumbnail throw error

export const perPageItems = [
  { value: 20, valueToShow: 20 },
  { value: 50, valueToShow: 50 },
  { value: 100, valueToShow: 100 },
  { value: 500, valueToShow: 500 }
];

export const userType = {
  superAdmin: 1,
  admin: 2,
  user: 3
};

export const ACTION_RENDER_ENUM = {
  Delete: 'Delete',
  Update: 'Update',
  View: 'View'
};

export const formattedMediaColumnKeys = {
  id: 'Id',
  file_id: 'File Id',
  file_name: 'File Name',
  file_description: 'File Description',
  file_size_kb: 'File Size',
  file_path: 'File Path',
  file_link: 'File Link',
  file_extension: 'File Extension',
  updated_by: 'Updated By',
  created_by: 'Created By',
  created_at: 'Created At',
  updated_at: 'Updated At',
  deleted_at: 'deleted_at',
  deleted_by: 'Deleted by'
};

export const formattedThemeIconColumnKeys = {
  id: 'Id',
  name: 'Name',
  short_code: 'Short Code',
  description: 'Description',
  theme_media_url: 'Theme Media URL',
  theme_id: 'Theme Id',
  is_active: 'Active Status',
  created_at: 'Created At',
  updated_at: 'Updated At'
};

export const customHeaderNames = {
  file_id: 'File ID',
  file_name: 'File Name',
  fileUrl: 'File URL',
  thumbnail: 'Thumbnail',
  file_link: 'File Link'
};

export const MEDIA_SERVER_RENDERED_COLUMN_IDS = [
  // 'id',
  // 'deleted_by',
  'file_id',
  'file_name',
  'file_size_kb',
  'file_extension',
  'file_path',
  'created_by',
  'updated_by'
];

export const THEME_SERVER_RENDERED_COLUMN_IDS = [
  'id',
  'name',
  'short_code',
  'is_active',
  'description',
  'theme_media_url',
  'created_at',
  'updated_at'
];

export const userMediaColDefs = [
  {
    headerName: 'file_id',
    field: 'file_id',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'file_name',
    field: 'file_name',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'file_description',
    field: 'file_description',
    cellRenderer: 'FileDescriptionRenderer',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'file_size_kb',
    field: 'file_size_kb',
    suppressHeaderMenuButton: true,
    filter: 'agNumberColumnFilter',
    filterParams: { defaultOption: 'greaterThanOrEqual' }
  },
  {
    headerName: 'file_extension',
    field: 'file_extension',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'file_path',
    field: 'file_path',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'created_by',
    field: 'created_by',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'updated_by',
    field: 'updated_by',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'created_at',
    field: 'created_at',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'updated_at',
    field: 'updated_at',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'file_link',
    field: 'file_link',
    cellRenderer: 'UrlRenderer',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'thumbnail',
    field: 'thumbnail',
    cellRenderer: 'ThumbnailCellRenderer',
    filter: false,
    sortable: false
  },
  {
    headerName: 'Actions',
    field: 'actions',
    cellRenderer: 'UserMediaActionRenderer',
    minWidth: 200,
    filter: false,
    sortable: false
  }
];

export const themeIconColDefs = [
  {
    headerName: formattedThemeIconColumnKeys['id'],
    field: 'id',
    suppressHeaderMenuButton: true
  },
  {
    headerName: formattedThemeIconColumnKeys['name'],
    field: 'name',
    suppressHeaderMenuButton: true
  },
  {
    headerName: formattedThemeIconColumnKeys['short_code'],
    field: 'short_code',
    suppressHeaderMenuButton: true
  },
  {
    headerName: formattedThemeIconColumnKeys['theme_id'],
    field: 'theme_id',
    suppressHeaderMenuButton: true
  },
  // {
  //   headerName: formattedThemeIconColumnKeys['is_active'],
  //   field: 'is_active',
  //   cellRenderer: 'CheckMarkRenderer',
  //   suppressHeaderMenuButton: true,
  //   suppressFloatingFilterButton: false
  // },
  {
    headerName: formattedThemeIconColumnKeys['description'],
    field: 'description',
    cellRenderer: 'FileDescriptionRenderer',
    suppressHeaderMenuButton: true
  },
  {
    headerName: formattedThemeIconColumnKeys['created_at'],
    field: 'created_at',
    suppressHeaderMenuButton: true
  },
  {
    headerName: formattedThemeIconColumnKeys['updated_at'],
    field: 'updated_at',
    suppressHeaderMenuButton: true
  },
  {
    headerName: formattedThemeIconColumnKeys['theme_media_url'],
    field: 'theme_media_url',
    cellRenderer: 'UrlRenderer',
    suppressHeaderMenuButton: true
  },
  {
    headerName: 'Thumbnail',
    field: 'thumbnail',
    cellRenderer: 'ThumbnailCellRenderer',
    filter: false,
    sortable: false
  },
  {
    headerName: 'Actions',
    field: 'actions',
    cellRenderer: 'ActionRenderer',
    minWidth: 200,
    filter: false,
    sortable: false
  }
];

export const addMediaTableColDefs = [
  { headerName: 'File Id', field: 'fileId', flex: 1, cellStyle: { textAlign: 'left' } },
  { headerName: 'File Name', field: 'fileName', flex: 1, cellStyle: { textAlign: 'left' } },
  { headerName: 'File URL', field: 'fileUrl', cellRenderer: 'UrlCellRenderer', flex: 1 },
  { headerName: 'Thumbnail', field: 'thumbnail', cellRenderer: 'ThumbnailCellRenderer', flex: 1 }
];

export const navigationBar = [
  { path: '/', icon: 'dashboard', name: 'Dashboard' },
  { path: '/media', icon: 'perm_media', name: 'Media' },
  { path: '/user-media', icon: 'folder_shared', name: 'My Media' },
  { path: '/theme-icon', icon: 'view_agenda', name: 'Theme Icon' },
  { path: '/icons', icon: 'apps', name: 'Icons' }
];

export const cardCommonStyles = {
  boxShadow: '0px 0px 4px gray',
  height: '100%',
  width: '100%'
};
