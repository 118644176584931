import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackBarAlert: null,
  beatLoader: 0,
  feedbackObj: null
};

export const AlertsSlice = createSlice({
  name: 'AlertsSlice',
  initialState,
  reducers: {
    setSnackBarAlert: (state, action) => {
      state.snackBarAlert = action.payload;
    },
    setShowBeatLoader: (state) => {
      state.beatLoader += 1;
    },
    setHideBeatLoader: (state) => {
      state.beatLoader -= 1;
    },
    setFeedbackObj: (state, action) => {
      state.feedbackObj = action.payload;
    }
  }
});

export const { setSnackBarAlert, setShowBeatLoader, setHideBeatLoader, setFeedbackObj } = AlertsSlice.actions;

export default AlertsSlice.reducer;
